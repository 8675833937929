// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
// components
import { HelmetBlock } from "../components/HelmetBlock";
import { Layout } from "../components/Layout";
import { Header } from "../components/HeaderBlock";
import { PreFooterBlock } from "../components/PreFooterBlock";
import { FooterBlock } from "../components/FooterBlock";
import { NewsMainBlock } from "../components/NewsMainBlock";
import { RelatedPosts } from "../components/RelatedPosts";
// helpers
import {
  checkLangOnRedirect,
} from "../helpers/lang";
// static
import "../styles/main.scss";

const NewsSingle = ({ data }) => {
  useEffect(() => {
    checkLangOnRedirect(data.wpPost, true);
  }, [data.wpPost]);

  const pageList = data?.wpPost;
  const seo = pageList?.seo;
  const newsCats = data.wpPost.categories.nodes;
  return (
    <Layout data={pageList} isPost={true}>
      <HelmetBlock data={seo} />
      <Header />
      <main>
        <NewsMainBlock data={pageList} />
        <RelatedPosts databaseId={data.wpPost.databaseId} newsCats={newsCats} />
      </main>
      <PreFooterBlock />
      <FooterBlock />
    </Layout>
  );
};

export default NewsSingle;

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      databaseId
      title
      content
      date(formatString: "D,M,Y")
      seo {
        canonical
        metaDesc
        metaRobotsNoindex
        title
        opengraphTitle
        opengraphType
        opengraphSiteName
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          localFile {
            publicURL
          }
          mediaDetails {
            height
            width
          }
        }
      }
      language {
        code
      }
      translations {
        link
        slug
      }
      news {
        picturedesk {
          altText
          localFile {
            publicURL
          }
        }
        picturemob {
          altText
          localFile {
            publicURL
          }
        }
      }
      categories {
        nodes {
          link
          name
          databaseId
        }
      }
    }
  }
`;
